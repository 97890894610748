import React from 'react';
import { Link } from "gatsby"
import Container from './container'
import { StaticImage } from "gatsby-plugin-image"

export default function Header() {
  return (
    <div className='h-1/12w-100 py-2 bg-white drop-shadow-md'> 
      <Container>
        <div className='flex flex-row flex-wrap items-center'>
          <Link className='' id="logo" to="/">
            <StaticImage src="../../images/logos/ggplot2tor.png" className="h-12 w-48" placeholder='none'/>
          </Link>
          <div className="navigation flex flex-row flex-wrap">
            <Link className='md:ml-2 text-base text-gray-900 hover:text-gray-700 rounded px-2 py-2' activeClassName="active" to="/tutorials">Tutorials</Link>
            <Link className='md:ml-2 text-base text-gray-800  hover:text-gray-700 rounded px-2 py-2' activeClassName="active" to="/apps">Apps</Link>
            <Link className='md:ml-2 text-base text-gray-800  hover:text-gray-700 rounded px-2 py-2' activeClassName="active" to="/courses/ggplot2fundamentals">Courses</Link>
            <a href="https://christianb.gumroad.com/l/ggplot2cheatsheets" className='md:ml-2 text-base text-gray-800  hover:text-gray-700 rounded px-2 py-2' activeClassName="active">Cheat Sheets for free</a>

          </div>
        </div>
      </Container>
    </div>
  )
}

