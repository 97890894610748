import React from 'react';
import { Link } from "gatsby"
import Container from './container'


export default function Footer() {
  return (
    <div className='h-1/12 w-100 py-4 mb-10'> 
      <Container>
        <div className='flex flex-col md:flex-row justify-between border-t border-gray-200 pt-10'>
          <div className='w-100 md:w-1/4 mt-5 md:mt-0'>
            <h3 className='text-sm text-gray-500 font-bold'>ggplot2tor</h3>
            <p className='text-gray-500 text-sm leading-relaxed pt-2'>Tutorials, educational apps, cheat sheets and courses for you to master ggplot2</p>
          </div>

          <div className='w-100 md:w-1/4 mt-5 md:mt-0'>
            <h3 className='text-sm text-gray-500 font-bold'>Creator and author</h3>
            <p className='text-gray-500 text-sm leading-relaxed pt-2'>I am an Instructional Designer and a former educational scientist with a curiosity for web development and data visualization. Find me <a className='text-blue-500' href='https://christianburkhart.de/'>on my personal homepage</a> or <a href="https://www.linkedin.com/in/christian-burkhart/" className="text-blue-500">LinkedIn</a>.</p>
          </div>

          <div className='w-100 md:w-1/4 mt-5 md:mt-0'>
            <Link to='/impressum' className='text-sm text-gray-500 hover:text-gray-700 block'>Impressum</Link>
            <Link to='/privacy' className='text-sm text-gray-500 hover:text-gray-700'>Privacy policy</Link>
          </div>

          <div className=''></div>
          <div className=''></div>
        </div>
      </Container>
    </div>
  )
}


