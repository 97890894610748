import React from 'react'
import CourseCard from '../courses/fundamentals/coursecard'
import tw from "twin.macro"
import styled from 'styled-components'

const DivContainer = styled.div`

  ${tw`flex flex-row justify-center`}
  
  p {
    ${tw`text-sm m-0 p-0 text-gray-900`}

    &:first-child {
        ${tw`p-4`}
    }
  }

  a {
    ${tw`bg-pink-500 hover:bg-pink-600 text-pink-50 p-3 rounded mx-auto my-4 border-0`}
    }
`

const CourseCardTutorial = (props) => {
  return (
    <DivContainer className='my-4 md:my-8'>
      <CourseCard />
    </DivContainer>
  )
}

export default CourseCardTutorial