import React  from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = (props) => {
  return (
    <GatsbyImage className='mb-8 shadow' image={props.image.childImageSharp.gatsbyImageData} />
  )
}

export default Image;
