import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import {GrFormCheckmark} from 'react-icons/gr'


export default function CourseCard() {
  return (
      <div className="bg-grey-50 border-t-8 rounded shadow border-yellow-500 md:w-72">

        <div className="border-b-1 bg-gradient-to-br from-yellow-200 to-yellow-400 p-5 flex justify-center">
          <StaticImage src="../../../images/logos/logoggplot2fundamentals.svg" className="" height={50} width={200} alt="ggplot2 fundamentals" layout="fixed" objectFit="contain" placeholder='none' />
        </div>

        <div className="px-5">
          <p className="py-4 text-gray-800">A complete training course for ggplot2.</p>
        </div>


        <div className="p-5 pt-0">

          <div className="flex flex-row no-wrap items-center"><GrFormCheckmark className="mr-1"/><p className="text-sm text-gray-900">9 modules</p></div>

          <div className="flex flex-row no-wrap items-center"><GrFormCheckmark className="mr-1"/><p className="text-sm text-gray-900">11+ hours of videos</p></div>

          <div className="flex flex-row no-wrap items-center"><GrFormCheckmark className="mr-1"/><p className="text-sm text-gray-900">8 cheat sheets</p></div>

          <div className="flex flex-row no-wrap items-center"><GrFormCheckmark className="mr-1"/><p className="text-sm text-gray-900">3 web apps</p></div>

          <div className="flex flex-row no-wrap items-center"><GrFormCheckmark className="mr-1"/><p className="text-sm text-gray-900">Full source code</p></div>

          <div className="flex flex-row no-wrap items-center"><GrFormCheckmark className="mr-1"/><p className="text-sm text-gray-900">Unlimited updates</p></div>
          
        </div>


        <span className="block text-center my-4 ">
          <span className="line-through text-sm text-gray-400"><sup className="">$</sup>84.99  </span>
          <span className="text-3xl"><sup className="text-gray-600 text-base"> $</sup>39.99</span>
        </span>

        <div className='flex justify-content'>
          <a href="https://www.udemy.com/course/ggplot2-fundamentals/?couponCode=0602837F49025593D410" className="bg-pink-500 hover:bg-pink-600 text-pink-50 p-3 rounded mx-auto my-4">Register now on Udemy</a>
        </div>
      </div>
  )
}

