import React from "react"
import Helmet from "react-helmet"
import Header from './header'
import Footer from './footer'
import MdxProvider from './mdxprovider'
import { Link } from 'gatsby'

const Shell = (props) => {
  return(
    <div>

      <div className="bg-pink-600 w-100 flex justify-center">
        <Link to="/courses/ggplot2fundamentals" className="text-white p-1 text-xs text-pink-50 md:text-sm hover:underline">If you like this content, have a look at my new ggplot2 course.</Link>
      </div>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="Get really good at ggplot2 with tutorials, educational apps, and courses" />
        <title>ggplot2tor</title>
        <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        {/* <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&family=Source+Serif+Pro:wght@300;400;700&display=swap" rel="stylesheet" /> */}
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400&display=swap" rel="stylesheet" />
      </Helmet>

      <Header />
      <MdxProvider>
        {props.children}
      </MdxProvider>

      <Footer />
      
    </div>
  )
}


export default Shell