import React from "react"
import { MDXProvider } from "@mdx-js/react"

import Image from './image'
import { Link } from 'gatsby'
import Detector from './vizdetector'
import CourseCardTutorial from './coursecardtutorial'

const comps = { Detector, Image, Link}

export default function Layout({ children }) {
  return (
    <MDXProvider components={comps}>{children}</MDXProvider>
  )
}